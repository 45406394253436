// spinner keyframes
@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

.contact-popup{
	display: none;
	&.show{
		display: block;
	}
	background:url(../images/blackground_tile.jpg) repeat-x;
	.fp-tableCell{
		@include table-cell-overide;
	}
	.content{
		@include content-padding;
	}
	.contact-form{
		position: relative;
		&.dim{
			opacity: 0.5;
		}
	}
	.contact-form > *:not(select){
		display: block;
		margin-bottom: 20px;
	}
	.input-wrap{
		position: relative;
		label{
			position: absolute;
			opacity: 0;
			font-size: 14px;
			font-weight: normal;
			color: $global-place-holder-txt-color;
			padding: 0px 10px;
			top: 3px;
			transition: all 0.25s ease-out;
			z-index: 10;
			pointer-events: none;
		}
		&.floating-label{
			label{
				opacity: 1;
				top: 0;
			}
			input{
				padding-top: 20px;
				padding-bottom: 6px;
			}
		}
	}
	textarea, input[type=text], input[type=email]{
		background: #000;
		border: #666 1px solid;
		box-shadow: 0 0 0 1px #000;
		padding: 13px 10px;
		box-sizing: border-box;
		width: 100%;
		max-width: 820px;
		&:focus{
			border: 1px solid #ffffff;
		}
		&.invalid{
			border: #ff0000 1px solid;
		}
	}
	::-webkit-input-placeholder {
	   color: $global-place-holder-txt-color;
	}
	
	:-moz-placeholder { /* Firefox 18- */
	   color: $global-place-holder-txt-color;  
	}
	
	::-moz-placeholder {  /* Firefox 19+ */
	   color: $global-place-holder-txt-color;  
	}
	
	:-ms-input-placeholder {  
	   color: $global-place-holder-txt-color;  
	}
	input[type=text]#txt-input-last-name{
		display:none;
	}
	.nice-select, textarea{
		width: 100%;
		max-width: 820px;
		padding-top: 13px;
		padding-bottom: 13px;
		height: auto;
		line-height: 26px;
	}
	input[type=submit]{
		color: #000;
		background: #5CBF00;
		border: #7BFF00 1px solid;
		box-shadow: 0 0 0 1px #000;
		padding: 6px 20px;
		&:hover{
			background: #7BFF00;
		}
		&:focus{
			background: #7BFF00;
		}
		&:disabled{
			background: #2f2f2f;
			border-color: #666;
		}
	}
	.nice-select .list > li:first-of-type{
		display: none;
	}
	.subject-context-msg{
		height: 26px;
	}
	.submit-wrap{
		.spinner{
			display: inline;
			margin-left: 10px;
			opacity: 0;
			transition: all .5s;
			&.show{
				opacity: 1;
			}
			&> div {
				width: 16px;
				height: 16px;
				background-color: #7BFF00;
				border-radius: 100%;
				display: inline-block;
				animation: sk-bouncedelay 1s infinite ease-in-out both;
			}
		}
		.spinner .bounce1 {
			animation-delay: -0.32s;
		}
		.spinner .bounce2 {
			animation-delay: -0.16s;
		}
	}
	.msg-scrim{
		display: block;
		position: absolute;
		background-color: rgba(0,0,0,0.4);
		width: 100%;
		top: 0;
		bottom: 0;
		opacity: 0;
		transition: all .25s;
		pointer-events: none;
		z-index: 10;
		&.show{
			opacity: 1;
			pointer-events: auto;
		}
	}
	.sucess-msg,
	.failure-msg{
		position: absolute;
		display: block;
		color: #000;
		border-radius: 2px;
		min-width: 200px;
		max-width: 300px;
		padding: 20px;
		margin-left: 50px;
		opacity: 0;
		top: 40%;
		left: 47%;
		transform: translate(-50% ,-50%);
		transition: all .5s;
		transition-delay: .2s;
		pointer-events: none;
		z-index: 100;
		@include disableTextSelection;
		
		&.show{
			top: 45%;
			opacity: 1;
		}	
		&:after{
			content:"";
			display: block;
			position: absolute;
			border-style: solid;
			border-width: 20px 12px 0 20px;
			width: 0;
			height: 0;
			bottom: -19px;
			left: -3px;
			transform: skewX(-58deg);
		}
		.uiman-icn{
			position: absolute;
			background-image:url(../images/about_uicf_icn.svg);
			background-size:90px 90px;
			background-size: 100px 100px;
			width: 100px;
			height: 100px;
			left: -100px;
			bottom: -60px;
		}
	}
	.sucess-msg{
		background-color: #92FF46;
		&:after{
			border-color: #92FF46 transparent transparent transparent;
		}
	}
	.failure-msg{
		background-color: #E83317;
		&:after{
			border-color: #E83317 transparent transparent transparent;
		}
	}
}

// mobile layout mods
// ---------------------------------
@mixin mobile-form-font-format{
	font-size: 16px;
	line-height: 20px;
}
@mixin mobile-form-elment-format{
	@include mobile-form-font-format;
	padding-top: 9px;
	padding-bottom: 9px;
}
.mobilelayout{
	.contact-popup{
		.content{
			@include content-padding-mobile;
		}
		// reduce form components
		textarea, input[type=text], input[type=email]{
			@include mobile-form-elment-format;
		}
		.nice-select, .nice-select .option, #contact textarea {
			@include mobile-form-elment-format;
		}
		.subject-context-msg{
			@include mobile-form-font-format;
			height:auto;
		}
		.input-wrap{
			&.floating-label{
				input{
					padding-top: 16px;
					padding-bottom: 2px;
				}
			}
		}
	}
}
//tweak for small phones
@media (max-width: 340px) {
	.contact-popup .contact-form > *:not(select) {
		margin-bottom: 12px;
	}
}
