@mixin disableTextSelection{
	cursor: default;
	user-select: none;
}
@mixin clear-fix{
	content: "";
	display: table;
	clear: both;
}
@mixin center{
	margin-left: auto;
	margin-right: auto;
}
@mixin center-absolute{
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
@mixin center-horizontal{
  position: relative;
  left: 50%;
  transform: translatex(-50%);
}
@mixin center-vertical{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@mixin dead-center{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.clear{
	@include clear-fix;
}