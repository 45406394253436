//import global varibles and mixins
@import 'global.scss';

// Typography
// -------------------------------------
@mixin heading-font-base{
	font-family: 'Georgia', sans-serif;
	font-weight: bold;
	font-style: italic;
}
@mixin heading-font{
	@include heading-font-base;
	color: $global-body-font-color;
}
@mixin body-font-base{
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
}
@mixin body-font{
	@include body-font-base;
	font-size: 22px;
	line-height: 26px;
	color: $global-body-font-color;
}
@mixin sub-font{
	@include body-font-base;
	font-size: 18px;
	font-weight: normal;
	color: $global-body-font-color;
}
@mixin micro-font{
	@include body-font-base;
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: $global-body-font-color;
}
body *{
	text-rendering: geometricPrecision;
}
body,
input,
label,
select,
textarea{
	@include body-font;
}
h1, h2, h3, h4{
	@include heading-font;
}
h2{
	font-size: 40px;
}
h3{
	font-size: 32px;
}
a{
	color: $global-link-color;
	text-decoration: none;
}
a:hover{
	text-decoration:underline;
}

// mobile layout mods
// ---------------------------------
.mobilelayout{
	h2{
		font-size: 32px;
	}
}