[class*='back-btn-']{
	position: absolute;
	@include global-nav-button;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
	top: 24px;
	opacity: 0;
	transform: scale(0,0);
	&:after{
		@include global-nav-button-icn;
	}
}
.back-btn-l{
	left: 24px;
	&:after{
		@include nav-icn-arrow-l;
	}	
}
.back-btn-r{
	right: 24px;
	&:after{
		@include nav-icn-arrow-r;
	}
}