#works{
	.fp-tableCell{
		@include table-cell-overide;
	}
	.content{
		@include content-padding;
		padding-bottom: 60px;
	}
	h2{
		.icon{
			top: 6px;
			@include title-icon-what-i-do;
		}
	}
	.grid-pad{
		padding: 0px;
	}
	.portfolio{
		position: relative;
		//margin-bottom: 60px;
		width: 100%;
	}
} 
.portfolio{
	.grid-sizer, .portfolio-item{
		width: 20%;
	}
	.portfolio-item{
		padding-left: 20px;
		margin-bottom: 20px;
		& *{
			box-sizing: border-box;
		}
		img{
			max-width: 100%;
			margin-bottom: 4px;
			border: 1px solid #3d3d3d;
			border-radius: 8px;
		}
		h3{
			@include body-font-base;
			font-weight: normal;
			font-style: normal;
			font-size: 16px;
			line-height: 16px;
			color: #80F600;
			margin-bottom: 2px;
		}
		p{
			@include body-font-base;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: #CCCCCC;
			margin-bottom: 0;
		}
	}
	&:after{
		@include clear-fix;
	}
}

// mobile layout mods
// ---------------------------------
.mobilelayout{
	#works{
		.content{
			padding: 40px 4px 60px 4px;
		}
	}
	.portfolio{
		margin-left: -5px;
		.portfolio-item{
			margin-bottom: 4px;
			padding-left: 10px;
			// hide title and info text
			h3, p{
				display: none;
			}
		}
	}
}
