$link-top: -5px;
$link-top-active: -3px;
nav{
	position: absolute;
	background: #000;
	border-bottom: #333 1px solid;
    text-align: center;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
	width: 100%;
	height: 46px;
	top: 0px;
	z-index: 1000;
	transition: top .5s;
	ul{
		margin: 0;
		padding: 2px 0;
		@include disableTextSelection;
	}
	li{
		display: inline-block;
		position: relative;
		min-width: 112px;
		height: 16px;
		top: 12px;
		&.left-side{
			border-left: #333 2px solid;
		}
		&.right-side{
			border-right: #333 2px solid;
		}
		&.active a{
			color: $global-uicf-green;
			&:hover{
				cursor: default;
			}
			&:active{
				top: $link-top;
			}
		}
	}
	a{
		position: relative;
		color: #fff;
		font-family: Georgia;
		font-weight: bold;
		font-style: italic;
		font-size: 17px;
		color: #B3B3B3;
		text-decoration: none;
		top: $link-top;
		cursor: pointer;
		transition: top .15s;
		&:hover{
			color: #fff;
			text-decoration: none;
		}
		&:active{
			top: $link-top-active;
		}
	}
	.home{
		position: relative;
		.icon{
			position: relative;
			display: block;
			background: url(../images/uicf_icon@2x.png) no-repeat;
			background-position: 0px 0px;
			background-size: 76px 104px;
			height: 52px;
			width: 76px;
			margin: 0 auto;
			top: 1px;
			&:hover{
				background-position: 0px -52px;
			}
		}
	}
	.home:before{
		content: "";
		display: block;
		position: absolute;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;
		border-top: 40px solid #000;
		width: 0; 
		height: 0; 
		margin: 0 auto;
		left: 0;
		right: 0;
		top: 24px;
		z-index: -1;
	}
	.home:after{
		content: "";
		display: block;
		position: absolute;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;
		border-top: 40px solid #333;
		width: 0; 
		height: 0; 
		margin: 0 auto;
		left: 0;
		right: 0;
		top: 26px;
		z-index: -2;
	}
	&.hidden{
		top: -90px;
	}
	.menu-btn{
		position: absolute;
		height: 16px;
		width: 20px;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
		.cheese-burger{
			position: absolute;
			background-color: #fff;
			height: 2px;
			width: 100%;
		}
		.top-bun{
			top: 0;
		}
		.crabby-patty{
			top: 7px;
		}
		.bottom-bun{
			top: 14px;
		}
	}
}
.desktoplayout{
	nav{
		.menu-btn{
			display: none;
		}
	}
}
.mobilelayout{
	nav{
		li{
			&.about-link, &.works-link, &.contact-link, &.resume-link{
				display: none;
			}
		}
	}
}