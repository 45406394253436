$nav-icn-sprite-url: url(../images/nav_icons_sprite.svg);

// pop icons 
// --------------------------------------------
@mixin nav-icn-sprite-base{
	background-image: $nav-icn-sprite-url;
	background-repeat: no-repeat;
	background-size: 102px 34px;
	height: 34px;
	width: 34px;
}
@mixin nav-icn-arrow-l{
	@include nav-icn-sprite-base;
	background-position: 0px 0px;
}
@mixin nav-icn-arrow-r{
	@include nav-icn-sprite-base;
	background-position: -34px 0px;
}
@mixin nav-icn-close-x{
	@include nav-icn-sprite-base;
	background-position: -68px 0px;
}