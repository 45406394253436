$about-fav-movies-sprite-url: url(../images/fav_movies_sprite@2x.jpg);
$about-fav-games-sprite-url: url(../images/fav_games_sprite@2x.jpg);
$about-fav-music-sprite-url: url(../images/fav_music_sprite@2x.jpg);
$about-fav-people-sprite-url: url(../images/fav_people_sprite@2x.jpg);


// fav movies
// --------------------------------------------
@mixin fav-mov-base{
	background-image: $about-fav-movies-sprite-url;
	background-size: 1010.752688172043%;
	padding-top: 149.462365591398%;
}
@mixin fav-mov-pacific-rim{
	@include fav-mov-base;
	background-position: 0.059031877214% 50%;
}
@mixin fav-mov-guardians-of-the-galaxy{
	@include fav-mov-base;
	background-position: 11.157024793388% 50%;
}
@mixin fav-mov-casino-royale{
	@include fav-mov-base;
	background-position: 22.255017709563% 50%;
}
@mixin fav-mov-kung-fu-hustle{
	@include fav-mov-base;
	background-position: 33.353010625738% 50%;
}
@mixin fav-mov-hot-fuzz{
	@include fav-mov-base;
	background-position: 44.503546099291% 50%;
}
@mixin fav-mov-the-incredibles{
	@include fav-mov-base;
	background-position: 55.548996458087% 50%;
}
@mixin fav-mov-scott-pilgrim{
	@include fav-mov-base;
	background-position: 66.646989374262% 50%;
}
@mixin fav-mov-iron-giant{
	@include fav-mov-base;
	background-position: 77.744982290437% 50%;
}
@mixin fav-mov-army-of-darkness{
	@include fav-mov-base;
	background-position: 88.842975206612% 50%;
}
@mixin fav-mov-unbreakable{
	@include fav-mov-base;
	background-position: 99.940968122786% 50%;
}

// fav games
// --------------------------------------------
@mixin fav-game-base{
	background-image: $about-fav-games-sprite-url;
	background-size: 1010.752688172043%;
	padding-top: 133.333333333333%;
}
@mixin fav-game-no-more-heros{
	@include fav-game-base;
	background-position: 0.059031877214% 50%;
}
@mixin fav-game-burnout-revenge{
	@include fav-game-base;
	background-position: 11.157024793388% 50%;;
}
@mixin fav-game-viewtiful-joe{
	@include fav-game-base;
	background-position: 22.255017709563% 50%;
}
@mixin fav-game-tower-fall{
	@include fav-game-base;
	background-position: 33.353010625738% 50%;
}
@mixin fav-game-infinity-blade{
	@include fav-game-base;
	background-position: 44.503546099291% 50%;
}
@mixin fav-game-castle-crashers{
	@include fav-game-base;
	background-position: 55.548996458087% 50%;
}
@mixin fav-game-street-fighter-Alpha-3{
	@include fav-game-base;
	background-position: 66.646989374262% 50%;
}
@mixin fav-game-need-for-speed-most-wanted{
	@include fav-game-base;
	background-position: 77.744982290437% 50%;
}
@mixin fav-game-zelda-phantom-hour-glass{
	@include fav-game-base;
	background-position: 88.842975206612% 50%;
}
@mixin fav-game-zone-of-the-enders{
	@include fav-game-base;
	background-position: 99.940968122786% 50%;
}
// fav music
// --------------------------------------------
@mixin fav-music-base{
	background-image: $about-fav-music-sprite-url;
	background-size: 1010.752688172043%;
	padding-top: 100%;
}
@mixin fav-music-social-distortion{
	@include fav-music-base;
	background-position: 0.059031877214% 50%;
}
@mixin fav-music-yeah-yeah-yeahs{
	@include fav-music-base;
	background-position: 11.157024793388% 50%;
}
@mixin fav-music-gorillaz{
	@include fav-music-base;
	background-position: 22.255017709563% 50%;
}
@mixin fav-music-afi{
	@include fav-music-base;
	background-position: 33.353010625738% 50%;
}
@mixin fav-music-set-your-goals{
	@include fav-music-base;
	background-position: 44.503546099291% 50%;
}
@mixin fav-music-green-day{
	@include fav-music-base;
	background-position: 55.548996458087% 50%;
}
@mixin fav-music-ac-dc{
	@include fav-music-base;
	background-position: 66.646989374262% 50%;
}
@mixin fav-music-fiona-apple{
	@include fav-music-base;
	background-position: 77.744982290437% 50%;
}
@mixin fav-music-crystal-method{
	@include fav-music-base;
	background-position: 88.842975206612% 50%;
}
@mixin fav-music-garbage{
	@include fav-music-base;
	background-position: 99.940968122786% 50%;
}
// fav people
// --------------------------------------------
@mixin fav-people-base{
	background-image: $about-fav-people-sprite-url;
	background-repeat: no-repeat;
	background-size: 102.564102564103%;
	width: 100%;
	max-width: 240px;
	padding-top: 100%;
}
@mixin fav-people-chris-salmon{
	@include fav-people-base;
	background-position: 0 0.113378684807%;
}
@mixin fav-people-tim-spangler{
	@include fav-people-base;
	background-position: 0 9.183673469388%;
}
@mixin fav-people-gary-boohood{
	@include fav-people-base;
	background-position: 0 18.253968253968%;
}
@mixin fav-people-julius-santiago{
	@include fav-people-base;
	background-position: 0 27.324263038549%;
}
@mixin fav-people-matt-bell{
	@include fav-people-base;
	background-position: 0 36.394557823129%;
}
@mixin fav-people-rodney-olmos{
	@include fav-people-base;
	background-position: 0 45.46485260771%;
}
@mixin fav-people-pat-carver{
	@include fav-people-base;
	background-position: 0 54.53514739229%;
}
@mixin fav-people-elvir-tatarevic{
	@include fav-people-base;
	background-position: 0 63.605442176871%;
}
@mixin fav-people-beau-brewer{
	@include fav-people-base;
	background-position: 0 72.675736961451%;
}
@mixin fav-people-jaimee-christenson{
	@include fav-people-base;
	background-position: 0 81.746031746032%;
}
@mixin fav-people-stephane-imbert{
	@include fav-people-base;
	background-position: 0 90.816326530612%;
}
@mixin fav-people-tony-marinello{
	@include fav-people-base;
	background-position: 0 99.886621315193%;
}

// fav books
// --------------------------------------------

@mixin fav-book-design-of-everday-things{
	background-image: url(../images/fav_books/book_design_of_everyday_things.jpg);
}
@mixin fav-book-dont-make-me-think {
	background-image: url(../images/fav_books/book_dont_make_me_think.jpg);
}
@mixin fav-book-design-thinking{
	background-image: url(../images/fav_books/book_design_thinking.jpg);
}
@mixin fav-book-elements-of-ux{
	background-image: url(../images/fav_books/book_elements_of_ux.jpg);
}
@mixin fav-book-articulating-design-decisions{
	background-image: url(../images/fav_books/book_articulating_design_decisions.jpg);
}
@mixin fav-book-art-of-inovation{
	background-image: url(../images/fav_books/book_art_of_inovation.jpg);
}
@mixin fav-book-changed-by-design{
	background-image: url(../images/fav_books/book_changed_by_design.jpg);
}
@mixin fav-book-creative-cofidence{
	background-image: url(../images/fav_books/book_creative_cofidence.jpg);
}
@mixin fav-booknever-split-the-difference{
	background-image: url(../images/fav_books/book_never_split_the_difference.jpg);
}
@mixin fav-book-thinking-fast-and-slow{
	background-image: url(../images/fav_books/book_thinking_fast_and_slow.jpg);
}

