// logo
@mixin logo-type{
	background-image: url(../images/logo_type.svg);
}

// page down arrow
@mixin page-down-arrow-icn{
	background-image: url(../images/page_down_arrown_icn.svg);
}


// atractor cubes
@mixin home-cube-logos{
	background-image: url(../images/cube_face_logos.svg);
}


@mixin home-cube-face-01-a{
	background-image: url(../images/cube_face_01_a.svg);
}
@mixin home-cube-face-01-b{
	background-image: url(../images/cube_face_01_b.svg);
}


@mixin home-cube-face-02-a{
	background-image: url(../images/cube_face_02_a.svg);
}
@mixin home-cube-face-02-b{
	background-image: url(../images/cube_face_02_b.svg);
}


@mixin home-cube-face-03-a{
	background-image: url(../images/cube_face_03_a.svg);
}
@mixin home-cube-face-03-b{
	background-image: url(../images/cube_face_03_b.svg);
}


@mixin home-cube-face-04-a{
	background-image: url(../images/cube_face_04_a.svg);
}
@mixin home-cube-face-04-b{
	background-image: url(../images/cube_face_04_b.svg);
}
