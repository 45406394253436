.pop-shade{
	position: absolute;
	width: 100%;
	top: 0px;
	bottom: 0px;
	.shade-fill{
		position: absolute;
		top: 0;
		left: 0;
		background-color: #000000;
		width: 200%;
		height: 200%;
		transform: rotate3d(0,0,1,90deg);
		transform-origin: top left;
	}
	.preloader{
		width: 200px;
		height: 200px;
		overflow: hidden;
		@include dead-center;
		.fill{
			opacity: 0;
			background-color: #2E2E2E;
			width: 180px;
			height: 180px;
			@include dead-center;
		}
		.pulse{
			opacity: 0;
			position: absolute;
			background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .75) 50%, rgba(255, 255, 255, 0) 100%);
			width: 180px;
			height: 100px;
			left: 10px;
			transform: matrix(1, 0, 0, 1, 0, -80);
		}
		.stencil{
			position: absolute;
			background-image: url(../images/pop_loader.svg);
			width: inherit;
			height: inherit;
			top: 0px;
			left: 0px;
		}
	}
}
.loader{
	.pop-shade{
		.shade-fill{
			transform: rotate3d(0,0,1,0deg);
		}
	}
}