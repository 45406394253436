@import 'about_images.scss';
@import 'about_fav_images.scss';

$synopsis-element-spacing: 20px;
.synopsis{
	font-size: 18px;
	font-weight: normal;
	.content{
		padding-top: 20px;
		padding-bottom: 40px;
	}
	.synopsis-container{
		max-width: 752px;
		@include center;
	}
	h2{
		font-size: 28px;
		margin-bottom: $synopsis-element-spacing;
	}
	h3{
		font-size: 24px;
		margin-bottom: $synopsis-element-spacing;
	}
	p, li, div{
		@include sub-font;
	}
	hr{
    	border-color: transparent;
		@include synopis-div-line;
		@include center;
	}
	.divider-vertical{
		position: relative;
		@include divider-vert-img;
		float: left;
		height: 100%;
		left: -22px;
		&:after{
			content: "";
			display: block;
			position: absolute;
			@include synopis-div-arrow-icn;
			bottom: -30px;
			left: -9px;
		}
	}
	.divider-horizontal{
		position: relative;
		@include divider-hor-img;
		width: 100%;
		bottom: -21px;
	}
	.col-1-1{
		padding: $synopsis-element-spacing;
	}
	.col-1-2{
		padding: $synopsis-element-spacing;
	}
	.profile-icn{
		@include center;
		margin-bottom: $synopsis-element-spacing;
	}
	.hero-quote{
		@include body-font;
		.aurthor{
			@include micro-font;
		}
	}
	.thumbs-up-icn{
		@include synopis-like-icn;
		@include center;
		margin-bottom: $synopsis-element-spacing;
	}
	.thumbs-down-icn{
		@include synopis-dislike-icn;
		@include center;
		margin-bottom: $synopsis-element-spacing;
	}
}
.synopsis.professional-synopis-slide{
	.row-2{
		.col-content{
			height: 240px;
		}
	}
	.row-3{
		.col-content{
			height: 368px;
		}
	}
	.row-4{
		.col-content{
			height: 376px;
		}
	}
	.profile-icn{
		@include synopis-head-professional-icn;
	}
	.skills-cloud{
		@include synopis-skills-cloud;
	}
	.tools{
		@include synopis-tools;
	}
	.legend{
		text-align: left;
		margin-top: 4px;
		li{
			display: inline;
			margin-right: 10px;
			&:before{
				content: '';
				display: inline-block;
				height: 12px;
				width: 12px;
				margin-right: 6px;
			}
		}
		.primary{
			color: #7BFF00;
			&:before{
				background-color: #7BFF00;
				background-image: linear-gradient(180deg, rgba(175, 255, 100, 1) 0%, rgba(124, 255, 2, 1) 100%);
			}
		}
		.secondary{
			color: #666666;
			&:before{
				background-color: #666666;
			}
		}
	}
	.brain-diagram{
		@include synopis-brain-icn;
		@include center;
		margin-bottom: $vertical-element-spacing;
		margin-top: 20px;
	}
	.design-process-diagram{
		@include synopis-design-process-diagram;
		@include center;
	}
	h4.creative{
		color: $global-uicf-green;
		font-size: 24px;
	}
	h4.logical{
		@include body-font-base;
		font-size: 22px;
		font-style: normal;
  		color: #BFBFBF;
		margin-bottom: 10px;
	}
	//  fav books
	// -------------------------------------
	.fav-books{
		padding-left: 20px;
		&::after{
			@include clear-fix
		}
		.fav-grid-item{
			background-size: cover;
			width: 100%;
			padding-top: 153.1915%;
			margin-bottom: 20px;
		}
	}
	.design-of-everday-things{
		@include fav-book-design-of-everday-things;
	}
	.dont-make-me-think{
		@include fav-book-dont-make-me-think;
	}
	.design-thinking{
		@include fav-book-design-thinking;
	}
	.elements-of-ux{
		@include fav-book-elements-of-ux;
	}
	.articulating-design-decisions{
		@include fav-book-articulating-design-decisions;
	}
	.art-of-inovation{
		@include fav-book-art-of-inovation;
	}
	.changed-by-design{
		@include fav-book-changed-by-design;
	}
	.creative-confidence{
		@include fav-book-creative-cofidence;
	}
	.never-split-the-difference{
		@include fav-booknever-split-the-difference;
	}
	.thinking-fast-and-slow{
		@include fav-book-thinking-fast-and-slow;
	}
	
	
	//  fav people
	// -------------------------------------
	.respect{
		padding: 20px 0;
		.col-1-4{
			box-sizing: border-box;
			padding: 0 10px 20px;
			color: $global-body-font-color;
			&:hover{
				background-color:rgba(255,255,255,0.12);
				border-radius: 20px;
				cursor: pointer;
			}
		}
	}
	.profile-photo{
		box-sizing: border-box;
		padding: 5%;
	}
	.profile-img{
		border-radius: 50%;
		@include center;
	}
	.profile-name{
		line-height: 20px;
		height: 40px;
	}
	.chris-salmon .profile-img{
		@include fav-people-chris-salmon;
	}
	.tim-spangler .profile-img{
		@include fav-people-tim-spangler;
	}
	.gary-boohood .profile-img{
		@include fav-people-gary-boohood;
	}
	.julius-santiago .profile-img{
		@include fav-people-julius-santiago;
	}
	.matt-bell .profile-img{
		@include fav-people-matt-bell;
	}
	.rodney-olmos .profile-img{
		@include fav-people-rodney-olmos;
	}
	.pat-carver .profile-img{
		@include fav-people-pat-carver;
	}
	.elvir-tatarevic .profile-img{
		@include fav-people-elvir-tatarevic;
	}
	.beau-brewer .profile-img{
		@include fav-people-beau-brewer;
	}
	.jaimee-christenson .profile-img{
		@include fav-people-jaimee-christenson;
	}
	.stephane-imbert .profile-img{
		@include fav-people-stephane-imbert;
	}
	.tony-marinello .profile-img{
		@include fav-people-tony-marinello;
	}
}
.synopsis.personal-synopis-slide{
	.row-2{
		.col-content{
			height: 240px;
		}
	}
	.row-3{
		.col-content{
			height: 376px;
		}
	}
	.profile-icn{
		@include synopis-head-personal-icn;
	}
	.synopis-personality-cloud{
		@include synopis-personality-cloud;
	}
	
	.synopis-loves-chart{
		@include synopis-loves-chart
	}
	.movies-icn{
		@include synopis-movies-icn;
		@include center;
	}
	.games-icn{
		@include synopis-games-icn;
		@include center-horizontal;
	}
	.music-icn{
		@include synopis-music-icn;
		@include center-horizontal;
	}
	.fav-grid{
		margin-top: 20px;
		&:after{
			@include clear-fix;
		}
		.col-1-5{
			width: 20%;
			box-sizing: border-box;
			float: left;
			padding-right: 10px;
			margin-bottom: 10px;
		}
	}
	.fav-grid-item{
		border: #FFFFFF 1px solid;
	}
	
	// movies
	// -------------------------------------
	.mov-pacific-rim{
		@include fav-mov-pacific-rim;
	}
	.mov-guardians-of-the-galaxy{
		@include fav-mov-guardians-of-the-galaxy;
	}
	.mov-casino-royale{
		@include fav-mov-casino-royale;
	}
	.mov-kung-fu-hustle{
		@include fav-mov-kung-fu-hustle;
	}
	.mov-hot-fuzz{
		@include fav-mov-hot-fuzz;
	}
	.mov-the-incredibles{
		@include fav-mov-the-incredibles;
	}
	.mov-scott-pilgrim{
		@include fav-mov-scott-pilgrim;
	}
	.mov-iron-giant{
		@include fav-mov-iron-giant;
	}
	.mov-army-of-darkness{
		@include fav-mov-army-of-darkness;
	}
	.mov-unbreakable{
		@include fav-mov-unbreakable;
	}
	
	// games
	// -------------------------------------
	.game-no-more-heros{
		@include fav-game-no-more-heros;
	}
	.game-burnout-revenge{
		@include fav-game-burnout-revenge;
	}
	.game-viewtiful-joe{
		@include fav-game-viewtiful-joe;
	}
	.game-tower-fall{
		@include fav-game-tower-fall;
	}
	.game-infinity-blade{
		@include fav-game-infinity-blade;
	}
	.game-castle-crashers{
		@include fav-game-castle-crashers;
	}
	.game-streetfighter-alpha-3{
		@include fav-game-street-fighter-Alpha-3;
	}
	.game-need-for-speed-most-wanted{
		@include fav-game-need-for-speed-most-wanted;
	}
	.game-zelda-phantom-hourglass{
		@include fav-game-zelda-phantom-hour-glass;
	}
	.game-zone-of-the-enders-second-run{
		@include fav-game-zone-of-the-enders;
	}
	
	// music
	// -------------------------------------
	.music-social-distortion{
		@include fav-music-social-distortion;
	}
	.music-yeah-yeah-yeahs{
		@include fav-music-yeah-yeah-yeahs;
	}
	.music-gorillaz{
		@include fav-music-gorillaz;
	}
	.music-afi{
		@include fav-music-afi;
	}
	.music-thirty-seconds-to-mars{
		@include fav-music-set-your-goals;
	}
	.music-green-day{
		@include fav-music-green-day;
	}
	.music-ac-dc{
		@include fav-music-ac-dc;
	}
	.music-fiona-apple{
		@include fav-music-fiona-apple;
	}
	.music-cystal-method{
		@include fav-music-crystal-method;
	}
	.music-garbage{
		@include fav-music-garbage;
	}
}

//mobile layout
.mobilelayout{
		.synopsis{
		// hide vertical dividers and arrow caps
		.divider-vertical{
			display: none;
		}
		// colapse the grid
		.col-1-2{
			width: 100%;
		}
		// display likes and dislikes list inline
		.my-likes, .my-dislikes{
			li{
				display: inline;
				&:after{
					content: ", ";
				}
				&:last-of-type{
					&:before{
						content: "and ";
					}
					&:after{
						content: ".";
					}
				}
			}
		}
		&.personal-synopis-slide{
			.my-personality .col-content,
			.my-loves .col-content,
			.my-likes .col-content,
			.my-dislikes .col-content{
				// strip off ridged height
				height: auto;
			}
		}
		// colapse fav grids
		&.personal-synopis-slide .fav-grid {
    		width: 100%;
			.col-1-5{
				width: 33.333333%;
				&.mobile-exclude{
					display: none;
				}
			}
		}
		&.professional-synopis-slide{
			.my-skill-set .col-content,
			.my-tools .col-content,
			.my-brain .col-content,
			.my-likes .col-content,
			.my-dislikes .col-content{
				// strip off ridged height
				height: auto;
			}
		}
		&.professional-synopis-slide{
			.fav-books{
				.col-1-5{
					width: 33.333%;
				}
				.mobile-exclude{
					display: none;
				}
			}
			.respect{
				padding: 20px 4px;
				.col-1-4{
					width: 33.333%
				}
			}
		}
	}
}
