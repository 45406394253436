// global varables and mixins

//colors
$global-uicf-green: #7BFF00;
$global-body-font-color: #ccc;
$global-body-font-color-dark: #666;
$global-link-color: $global-uicf-green;
$global-place-holder-txt-color: #4F4F4F;

//shared styles

@mixin gobal-box-border{
	border: 1px solid #4D4D4D;
}

// layout
$vertical-element-spacing: 40px;
$vertical-element-spacing-mobile: 20px;

@mixin table-cell-overide{
	vertical-align: baseline;
}
@mixin content-padding{
	padding: 40px 60px 40px 40px;
}
@mixin content-padding-mobile{
	padding: 40px 20px 20px 20px;
}

@mixin global-nav-button{
	background-color: #7BFF00;
	border-radius: 50%;
	width: 64px;
	height: 64px;
	cursor: pointer;
}
@mixin global-nav-button-icn{
	content: "";
	position: absolute;
	display: block;
	@include dead-center;
}