// mobile layout trigger...
// js looks for "mobilelayout" on body:after and then applies it as a class to the body
// -------------------------
@media (min-width: 768px) {
	body{
		&:after{
			content:"desktoplayout";
			display: none;
		}
	}
}
@media (max-width: 767px) {
	body{
		&:after{
			content:"mobilelayout";
			display: none;
		}
	}
}


// portfolio / masonary grid
// -------------------------
@media (max-width: 1700px) {
	.portfolio{
		.grid-sizer{
			width: 25%;
		}
		.portfolio-item{
			width: 25%;
		}
	}
}
@media (max-width: 1400px) {
	.portfolio{
		.grid-sizer{
			width: 33.33%;
		}
		.portfolio-item{
			width: 33.33%;
		}
	}
}
@media (max-width: 767px) {
	.portfolio{
		.grid-sizer{
			width: 50%;
		}
		.portfolio-item{
			width: 50%;
		}
	}
}
// popup project viewer
// -------------------------
@media (max-width: 1050px) {
	.popup {
		.pop-content{
			padding: 20px 20px 0;
		}
		.pop-header{
			margin-left: 90px;
		}
	}
	.pop-close-btn{
		background-color: rgba(123, 255, 0, .90);
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
		left: 30px;
		top: 20px;
	}
}
// mobile tweaks for small phones
@media (max-width: 370px) {
	// about sub titles
	.mobilelayout #about article h3, .mobilelayout #about article h3.open {
		font-size: 22px;
		line-height: 28px;
	}
	// section titles
	.mobilelayout h2 {
		font-size: 30px;
	}
}

// attactor spacing for mobile phones
@media screen and (aspect-ratio: 19/9) {
	/* Styles for devices with a 19:9 aspect ratio */
	#home{
		.ui-man{
			left: -34.25%;
		}
	}
  }
@media screen and (aspect-ratio: 19.5/9) {
	/* Styles for devices with a 19.5:9 aspect ratio */
	#home{
		.ui-man{
			left: -36%;
		}
	}
  }
  @media screen and (aspect-ratio: 20/9) {
	/* Styles for devices with a 20:9 aspect ratio */
	#home{
		.ui-man{
			left: -38%;
		}
	}
  }