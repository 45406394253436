@import '_imports/reset.scss';
@import '_imports/normalize.scss';
@import '_imports/utilities.scss';
@import '_imports/global.scss';
@import '_imports/grid.scss';
@import '_imports/nice-select.scss';
@import '_imports/images.scss';
@import '_imports/nav_icon_images.scss';
@import '_imports/home_images.scss';
@import '_imports/typography.scss';
@import '_imports/nav.scss';
@import '_imports/layout.scss';
@import '_imports/preloader.scss';
@import '_imports/popups.scss';
@import '_imports/pop_contact.scss';
@import '_imports/back_btns.scss';
@import '_imports/mobile-menu.scss';

// bower:scss
// endbower

h2 .icon, h2 .after{
	display: inline-block;
	position: relative;
}
h2 .after{
	top: 10px;
	@include title-after;
}
@import '_imports/home_section.scss';
@import '_imports/about_section.scss';
@import '_imports/about_synopsis.scss';
@import '_imports/mobile_about_details.scss';
@import '_imports/works_section.scss';
@import '_imports/media_queries.scss';

#fp-nav{
	opacity: 1;
	transition: opacity .25s, right .5s;
}
#fp-nav.hidden{
	opacity: 0;
	right: 0;
}
#about{
	padding-top: 0 !important;
}


.loader{
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 9999;
}
// scroll bar... over ride styles set in scrolloverflow.js
.iScrollIndicator{
	background-color: $global-uicf-green !important;
}

//mobile layout overrides
@import '_imports/mobile_layout.scss';

// kill fp js warning
div[style*="z-index:9999999"][style*="background:red"]{
	display: none !important;
}
