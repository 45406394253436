#about{
	background-image:url(../images/blackground_tile.jpg);
	background-repeat:repeat-x;
	.fp-tableCell{
		@include table-cell-overide;
	}
	h2 .icon{
		top: 6px;
		@include title-icon-who-i-am;
	}
	article{
		position: relative;
		margin-bottom: $vertical-element-spacing;
		.header-icon{
			position: relative;
			float: left;
			width: 32px;
			height: 32px;
			border: rgba(255,255,255,0.40) 2px solid;
			border-radius: 50%;
			top: -4px;
			margin-right: 8px;
			transition: border-width 0.25s ease-in 1.25s;
			&:hover{
				border-color: rgba(255,255,255,0.80);
				cursor: pointer;
			}
			&.open{
				border-width: 4px;
				&:hover{
					border-color: rgba(255,255,255,0.40);
					cursor: default;
					align-content: center; 
				}
			}
			&:after{
				@include clear-fix;
			}
		}
		.title-txt{
			margin-bottom: 12px;
			& *{
				text-rendering: auto;
			}
		}
		h3{
			display: inline;
			font-weight: normal;
			font-size: 26px;
			letter-spacing: .5px;
			@include disableTextSelection;
			cursor: pointer;
			padding-right: 10px;
			&:hover{
				color: #fff;
			}
			&.open{
				font-size: 32px;
				line-height: 34px;
				&:hover{
					color: $global-body-font-color;
					cursor: default;
				}
			}
		}
		.txt{
			width: 70%;
			max-width: 860px;
		}
		p{
			letter-spacing: .25px;
			line-height: 26px;
			div{
				overflow: visible;
			}
		}
	}
	.introduction-slide{
		position: relative;
		.content{
			@include content-padding;
			padding-top: 86px;
		}
	}
	.introduction{
		.header-icon{
			width: 160px;
			height: 160px;
			margin-right: 24px;
		}
		.txt{
			overflow: hidden;
		}
	}
	.services, .wants{
		.txt{
			height: 0;
			overflow: hidden;
		}
	}
	[class*='col-'] {
		text-align: center;
	}
	.personal-synopsis-link, .professional-synopsis-link{
		display: inline-block;
		cursor: pointer;
		border-bottom: 1px solid rgba(0, 0, 0, 0);
		@include heading-font-base;
		margin-top: 8px;
		&:hover{
			text-decoration: none;
			border-bottom: 1px solid $global-link-color;
		}
	}
}

// mobile layout mods
// ---------------------------------
.mobilelayout{
	#about{
		.introduction-slide{
			.content{
				@include content-padding-mobile;
				padding-top: 86px;
			}
		}
		.introduction{
			.header-icon{
				width: 32px;
				height: 32px;
				margin-right: 8px;
			}
			.txt{
				height:0;
			}
		}
		article{
			padding-bottom: 10px;
    		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			margin-bottom: $vertical-element-spacing-mobile;
			.header-icon{
				&.open{
					border-width: 2px;
				}
			}
			h3, h3.open{
				font-size: 28px;
				line-height: 34px;
			}
			.txt{
				width: 100%;
				p{
					font-size: 18px;
					line-height: 24px;
					letter-spacing: .3px;
				}
			}
		}
	}
}