body{
	background:#000;
}
.lightning-bg{
	position: absolute;
	background: url(../images/lightning_bolt.svg) no-repeat;
	width: 1822px;
	height: 800px;
	left: -90px;
	top: 40px;
	pointer-events: none;
}

//element vertical spacing
h2{
	margin-bottom: $vertical-element-spacing;
}
h3{
	margin-bottom: 10px;
}
p:not(:last-of-type) {
	margin-bottom: 20px;
}
.mobilelayout{
	h2{
	margin-bottom: $vertical-element-spacing-mobile;
		&.after{
			top: 2px;
		}
	}
}
