@keyframes arrow-bounce {
    0%   {top: 10px;}
    1000%   {top: 25px;}
}

#home{
	.composition{
		background-image: linear-gradient(0deg, rgba(61, 69, 102, 1) 0%, rgba(24, 24, 33, 1) 80%);
		width: 100%;
		height: 100%;
	}
	.stars{
		position: absolute;
		background-image: url(../images/home_bg_stars.svg);
		background-repeat: repeat-x;
		width: 100%;
		height: 530px;
		top: 45px;
	}
	.moon{
		position: absolute;
		background-image: url(../images/home_bg_moon.svg);
		background-size: 100%;
		background-repeat: no-repeat;
		top: 45px;
		right: 0px;
		width: 45%;
		padding-top: 100%;
	}
	.cloud-01, .cloud-02, .cloud-03{
		background-image: url(../images/home_bg_cloud_sprite.svg);
		background-repeat: no-repeat;
		background-size: 100%;
		width: 100%;
		padding-top: 34.516129032258%;
	}
	.cloud-01{
		background-position: 0 0%;
	}
	.cloud-02{
		background-position: 0 49.54955%;
	}
	.cloud-03{
		background-position: 0 100%;
	}
	.cloud-box-01, .cloud-box-02, .cloud-box-03{
		position: absolute;
	}
	.cloud-box-01{
		width: 30%;
		transform:translate(-101%,260px);
	}
	.cloud-box-02{
		width: 50%;
		transform:translate(-101%,140px);
	}
	.cloud-box-03{
		width: 70%;
		transform:translate(-101%,0px);
	}
	
	.rocks{
		position: absolute;
		background-image: url(../images/home_bg_rocks.svg);
		background-size: 100%;
		padding-top: 35.9375%;
		width: 100%;
		bottom: 0;
	}
	.ui-man{
		position: absolute;
		width: 1100px;
		height: 720px;
		bottom: 0;
		@include center-absolute;
		.canvas{
			display: block;
			position: absolute;
			width: inherit;
			height: inherit;
		}
		.dom-overlay-container{
			display: block;
			position: absolute;
			width: inherit;
			height: inherit;
			top: 0;
			left: 0;
			overflow: hidden;
			pointer-events: none;
		}
	}
	
	
	.title-group{
		position: absolute;
		bottom: 8%;
		width: 590px;
		height: 380px;
		@include center-absolute;
		.mobilelayout &{
			width: 90%;
			bottom: 20px;
		}
	}
	.title-group-content{
		position: absolute;
		height: 100%;
		width: 590px;
		left: 50%;
		top: 50%;
		transform:translate(-50%, -50%);
		transform-origin: (bottom center);
	}
	
	$cube-size: 200;
	@mixin cube-face-green{
		background-image: linear-gradient(to bottom, #a4ff2e 0%, #7bff00 15%);
	}
	@mixin cube-face-black{
		background-image: linear-gradient(to bottom, #1F1F1F 0%, #000 15%);
	}
	.cube-container{
		position: absolute;
		top: 33px;
		perspective: 500px;
		&.left{
			left: 66px;
		}
		&.right{
			left: 322px;
		}
	}
	.cube{
		position:relative;
		height: $cube-size + px;
		width: $cube-size + px;
		transform-style: preserve-3d;
	}
	.cube-face{
		position: absolute;
		background-size: 100%;
		backface-visibility: hidden;
		height: inherit;
		width: inherit;
	}
	.face-front{
		transform: translateZ($cube-size / 2 + px);
	}
	.face-bottom{
		transform: rotateX(-90deg) translateZ($cube-size / 2 + px);
	}
	.face-back{
		transform: rotateX(180deg) translateZ($cube-size / 2 + px);
	}
	.face-top{
		transform: rotateX(90deg) translateZ($cube-size / 2 + px);
	}
	.cube-container{
		&.left{
			.face-front{
				@include home-cube-face-01-a;
			}
			.face-bottom{
				@include home-cube-face-04-a;
			}
			.face-back{
				@include home-cube-face-03-a;
			}
			.face-top{
				@include home-cube-face-02-a;
			}	
		}
		&.right{
			.face-front{
				@include home-cube-face-01-b;
			}
			.face-bottom{
				@include home-cube-face-04-b;
			}
			.face-back{
				@include home-cube-face-03-b;
			}
			.face-top{
				@include home-cube-face-02-b;
			}
		}
	}
	.title-txt{
		position: absolute;
		line-height: 0;
		text-indent: -9999px;
		white-space: nowrap;
		@include logo-type;
		background-size: 100%;
		background-repeat: no-repeat;
		width: 604px;
		height: 100px;
		padding: 0;
		margin: 0 auto;
		top: 280px;
		.mobilelayout &{
			height: 64px;
		}
	}
	.page-down-arrow{
		position: absolute;
		width: 80px;
		height: 80px;
		@include center-horizontal;
		top: 380px;
		cursor: pointer;
		.mobilelayout &{
			top: 345px;
		}
		.arrow-icn{
			position: absolute;
			@include page-down-arrow-icn;
			width: 40px;
			height: 40px;
			@include center-horizontal;
			top: 20px;
			
			animation: arrow-bounce .35s infinite;
    		animation-direction: alternate-reverse;
			animation-timing-function: ease-in-out;
		}
	}
}