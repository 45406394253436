.mobile-about-details{
	display: none;
	position: absolute;
	background-image: url(../images/blackground_tile.jpg);
	width: 100%;
	top: 0;
	left: 40px;
	bottom: 0;
	padding: 30px;
	opacity: 0;
	z-index: 9999;
	&:before{
		content: "";
		display: block;
		position: absolute;
		background-image: url(../images/lightning_bolt.svg);
		background-size: 200%;
		background-position: top center;
		left: 0;
		right:0;
		top: 0;
		bottom: 0;
	}
	.m-details-content{
		padding-bottom: 60px;
	}
	&.intro{
		.mobile-about-details-hero-icn{
			background-image:url(../images/about_me_icn.svg);
		}
	}
	&.services{
		.mobile-about-details-hero-icn{
			background-image:url(../images/about_uicf_icn.svg);
		}
	}
	&.wants{
		.mobile-about-details-hero-icn{
			background-image:url(../images/about_wants_icn.svg);
		}
	}
	.mobile-about-details-hero-icn{
		background-size: 100%;
		border: rgba(255, 255, 255, 0.4) 2px solid;
		border-radius: 50%;
		width: 120px;
		height: 120px;
		@include center;
		margin-bottom: 20px;
	}
	p{
		font-size: 18px;
	}
	.back-btn{
		position: absolute;
		@include global-nav-button;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
		top: 24px;
		left: 24px;
		opacity: 0;
		transform: scale(0,0);
		&:after{
			@include global-nav-button-icn;
			@include nav-icn-arrow-l
		}
		
	}
}