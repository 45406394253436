$about-sprite-url: url(../images/synopsis_sprite_responsive.svg);

// synopis images
// --------------------------------------------
@mixin synopis-sprite-base{
	background-image: $about-sprite-url;
	background-repeat: no-repeat;
	background-size: 752px 1918px;
}
@mixin synopis-div-line{
	@include synopis-sprite-base;
	background-position: 0px 0px;
	width: 752px;
	height: 28px;
}
@mixin synopis-loves-chart{
	background-image: $about-sprite-url;
	background-position: 0 7.44186%;
	background-size: 223.809524%;
	max-width: 100%;
	padding-top: 58.928571428571%; // image height / width *100
}
@mixin synopis-personality-cloud{
	background-image: $about-sprite-url;
	background-position: 0 18.953488%;
	background-size: 223.809524%;
	max-width: 100%;
	padding-top: 58.928571428571%;
}
@mixin synopis-skills-cloud{
	background-image: $about-sprite-url;
	background-position: 0 30.465116%;
	background-size: 223.809524%;
	max-width: 100%;
	padding-top: 58.928571428571%;
}
@mixin synopis-tools{
	background-image: $about-sprite-url;
	background-position: 0 42.15%;
	background-size: 223.809524%;
	max-width: 100%;
	padding-top: 46.428571428571%;
}
@mixin synopis-design-process-diagram{
	@include synopis-sprite-base;
	background-position: 0px -905px;
	width: 318px;
	height: 303px;
}
@mixin synopis-brain-icn{
	@include synopis-sprite-base;
	background-position: 0px -1214px;
	width: 152px;
	height: 170px;
}
@mixin synopis-like-icn{
	@include synopis-sprite-base;
	background-position: 0px -1384px;
	width: 102px;
	height: 102px;
}
@mixin synopis-dislike-icn{
	@include synopis-sprite-base;
	background-position: 0px -1486px;
	width: 102px;
	height: 102px;
}
@mixin synopis-head-personal-icn{
	@include synopis-sprite-base;
	background-position: 0px -1588px;
	width: 102px;
	height: 102px;
}
@mixin synopis-head-professional-icn{
	@include synopis-sprite-base;
	background-position: 0px -1690px;
	width: 102px;
	height: 102px;
}
@mixin synopis-div-arrow-icn{
	@include synopis-sprite-base;
	background-position: 0px -1906px;
	width: 20px;
	height: 12px;
}

// favorites
// --------------------------------------------
@mixin synopis-movies-icn{
	@include synopis-sprite-base;
	background-position: 0px -1792px;
	width: 50px;
	height: 50px;
}
@mixin synopis-games-icn{
	@include synopis-sprite-base;
	background-position: 326px -78px;
	width: 700px;
	height: 50px;
}
@mixin synopis-music-icn{
	@include synopis-sprite-base;
	background-position: 0px -28px;
	width: 700px;
	height: 50px;
}

// dividers (dotted lines)
// --------------------------------------------
@mixin divider-vert-img{
	background-image: url(../images/dotted_tile_vert.svg);
	background-repeat:repeat-y;
	width: 2px;
}
@mixin divider-hor-img{
	background-image: url(../images/dotted_tile_hor.svg);
	background-repeat:repeat-x;
	height: 2px;
}
// back arrows
// --------------------------------------------
@mixin arrow-left-icn{
	@include synopis-sprite-base;
	background-position: 0px -1842px;
	width: 32px;
	height: 32px;
}
@mixin arrow-right-icn{
	@include synopis-sprite-base;
	background-position: 0px -1874px;
	width: 32px;
	height: 32px;
}