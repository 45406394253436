$title-icon-sprite-url: url(../images/title_icons_sprite.svg);

//title icons
// --------------------------------------------
@mixin title-icon{
	background-image: $title-icon-sprite-url;
	background-repeat: no-repeat;
	background-size: 412px 42px;
}
@mixin title-icon-who-i-am{
	@include title-icon;
	background-position: 0 0;
	width: 40px;
	height: 42px;
}
@mixin title-icon-what-i-do{
	@include title-icon;
	background-position: -40px 0;
	width: 46px;
	height: 42px;
}
@mixin title-after{
	@include title-icon;
	background-position: -135px 0;
	width: 277px;
	height: 42px;
}

// scale mods for mobile layout
@mixin title-icon-who-i-am-mobile-mod{
	background-size: 314px 32px;
	width: 32px;
	height: 32px;
}
@mixin title-icon-what-i-do-mobile-mod{
	background-size: 314px 32px;
	width: 36px;
	height: 32px;
}