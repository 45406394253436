$pop-framing-width: 854px;

@import 'popup_images.scss';

.popups{
	display: none;
	position: absolute;
	top: 0px;
	width: 100%;
	z-index: 2000;
}
.pop-shade{
	position: absolute;
	width: 100%;
	left: 0;
	top: 0px;
	bottom: 0px;
	z-index: 100;
	.shade-fill{
		position: absolute;
		top: 0;
		left: 0;
		background-image: url(../images/black.png);
		background-color: #000;
		width: 200%;
		height: 200%;
		transform: rotate3d(0,0,1,90deg);
		transform-origin: top left;
	}
}
.pop-scrim{
	position: absolute;
	width: 100%;
	top: 0px;
	bottom: 0px;
	.scrim-backdrop{
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		background-image: url(../images/blackground_tile.jpg);
		background-repeat:repeat-x;
		background-color: #000000;
		height: inherit;
		width: inherit;
	}
}
.popup{
	position: relative;
	.pop-frame{
		max-width: $pop-framing-width;
		@include center;
	}
	.pop-content{
		padding-top: $vertical-element-spacing;
		padding-bottom: $vertical-element-spacing;
	}
	.pop-header{
		position: relative;
		border-left: 1px solid #4D4D4D;
		margin-bottom: 30px;
		padding-left: 20px;
		.category-icon{
			display: inline-block;
			margin-right: 10px;
			&.game{
				@include pop-game-icn;
			}
			&.website{
				@include pop-website-icn;
			}
			&.logo{
				@include pop-logo-icn;
			}
			&.contact{
				@include pop-message-icn;
				&:hover{
					@include pop-message-hover-icn;
				}
			}
			
		}
		.title-txt{
			display: inline-block;
		}
		h2{
			font-size: 32px;
			line-height: normal;
			margin-bottom: 6px;
		}
		.micro-txt{
			@include micro-font;
		}
	}
}
.pop-project-viewer{
	display: none;
	overflow: hidden;
	box-sizing: border-box;
	&.show{
		display: block
	}
	.hero-img,
	.portfolio-img{
		@include gobal-box-border;
		max-width: 100%;
		display: block;
		border: 1px solid #3d3d3d;
		border-radius: 8px;
		
	}
	.portfolio-img{
		margin-bottom: 20px;
	}
	.hero-grid{
		display: flex;
		justify-content: space-around;
		align-items: stretch;
		flex-wrap: nowrap;
		margin-bottom: 20px;
	}
	.hero-txt, .hero-imgs{
		width: 100%;
	}
	.hero-txt{
		@include gobal-box-border;
		padding: 20px;
		margin-left: 10px;
		border: 1px solid #3d3d3d;
		border-radius: 8px;
	}
	.hero-imgs{
		margin-right: 10px;
	}
	.hero-img{
		display: block;
	}
	.hero-mini-img{
		@include gobal-box-border;
		margin-top: 20px;
		margin-right: 20px;
		img{
			max-width: 100%;
			display: block;
		}
	}
	h3{
		@include body-font-base;
		font-size: 24px;
		font-style: normal;
		margin-bottom: 16px;
	}
	p{
		font-size: 18px;
		margin-bottom: 18px;
	}
	p.blurb{
		font-size: 24px;
	}
	p.description{
		font-size: 18px;
		line-height: 22px;
	}
	p.sub-txt{
		@include micro-font;
		color: $global-body-font-color-dark;
	}
	.flex-row{
		display: flex;
		justify-content: space-around;
		align-items: stretch;
		flex-wrap: nowrap;
		margin-right: -20px;
	}
	.flex-1-3, .flex-2-3{
		margin-right: 20px;
		img{
			display: block;
			@include gobal-box-border;
			max-width: 100%
		}
	}
	.flex-2-3{
		flex-grow: 2;
	}
	
	.logos{
		.flex-row{
			margin-bottom:  20px;
		}
	}
	.mathathon{
		.portfolio-img{
			max-width: none;
			width: 100%;
		}
	}
	.before-after-viewer{
		.before-after-img{
			position: relative;
			padding-top: 78.125%;
			margin-bottom: 8px;
		}
		.portfolio-img{
			position: absolute;
			top: 0;
			margin-bottom: 0;
			&.before{
				display: none;
			}
		}
		.link-toggle{
			font-size: 18px;
			text-align: center;
			margin-bottom: 20px;
			user-select: none;
			&.before{
				display: none;
			}
		}
		
	}
}
.close-btn-frame{
	position: absolute;
	max-width: $pop-framing-width;
	top: 0px;
	@include center-absolute;
}
.pop-close-btn{
	display: none;
	position: absolute;
	@include global-nav-button;
	top: 38px;
	left: -90px;
	z-index: 100;
	&:after{
		@include global-nav-button-icn;
		@include nav-icn-close-x;
	}
}
.row-open-in-figma-btn{
	text-align: center;
	padding-top: 20px;
	margin-top: 18px;
	border-top: #3d3d3d 1px solid;
}
.open-in-figma-btn{
	display: inline-flex;
	flex-direction: row;
	gap: 6px;

	color:#fff;
	background-color: #000;
	border-radius: 8px;
	border: #666 1px solid;
	padding: 12px 24px;
	cursor: pointer;
	&:hover{
		text-decoration: none;
		background-color: #171717;
		border-color: #fff;
	}
	.figma-icn{
		@include figma-icon;
	}
	.btn-label{
		font-size: 22px;
		font-weight: 100;
	}
}

//mobile layout mods
// --------------------
.mobilelayout{
	// hide the title category icon
	.popup{
		.pop-header{
			margin-bottom: 20px;
			.category-icon{
				display: none;
			}
		}
		//reduce title
		h2{
			font-size: 20px;
		}
		// hide the title hero images
		.hero-imgs{
			display: none;
		}
		// remove center gutter margin
		.hero-txt{
			margin-left: 0;
		}
	}
	.pop-close-btn{
		top: 14px;
		left: 30px;
	}
}
