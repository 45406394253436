.mobile-menu{
	display: none;
	position: absolute;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.85);
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 900;
	ul{
		margin-top: 120px;
	}
	li{
		font-size: 34px;
		line-height: 34px;
		padding: 12px 0;
		margin: 0 20px;
		border-radius: 10px;
		@include disableTextSelection;
		&:active{
			background-color: rgba(255, 255, 255, 0.15);;
		}
	}
	.mobilelayout &{
		&.show{
			display: block;
		}
	}
}

