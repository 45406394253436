$pop-icn-sprite-url: url(../images/pop_title_icons_sprite.svg);

// pop icons 
// --------------------------------------------
@mixin pop-icn-sprite-base{
	background-image: $pop-icn-sprite-url;
	background-repeat: no-repeat;
	background-size: 412px 56px;
	height: 56px;
}
@mixin pop-game-icn{
	@include pop-icn-sprite-base;
	background-position: 0px 0px;
	width: 92px;
}
@mixin pop-website-icn{
	@include pop-icn-sprite-base;
	background-position: -92px 0px;
	width: 78px;
}
@mixin pop-logo-icn{
	@include pop-icn-sprite-base;
	background-position: -170px 0px;
	width: 98px;
}
@mixin pop-message-icn{
	@include pop-icn-sprite-base;
	background-position: -268px 0px;
	width: 72px;
}
@mixin pop-message-hover-icn{
	@include pop-icn-sprite-base;
	background-position: -340px 0px;
	width: 72px;
}

// misc
// --------------------------------------------
@mixin figma-icon{
	background-image: url(../images/figma_icn.svg);
	background-repeat: no-repeat;
	height: 30px;
	width: 20px;
}