$pad: 20px;

.grid {
  margin: 0 0 $pad 0;
  box-sizing: border-box;
  
  &:after {
    @include clear-fix;
  }
}

.col-1-1,
.col-2-3,
.col-1-3,
.col-1-2,
.col-1-4,
.col-1-5,
.col-1-8{
  float: left;
  padding-right: $pad;
  box-sizing: border-box;
}
.col-1-1 {
  width: 100%;
}
.col-2-3 {
  width: 66.66%;
}
.col-1-3 {
  width: 33.33%;
}
.col-1-2 {
  width: 50%;
}
.col-1-4 {
  width: 25%;
}
.col-1-5{
	width: 20%;
}
.col-1-8 {
  width: 12.5%;
}

// Opt-in outside padding
.grid-pad {
  padding: $pad 0 $pad $pad;
  [class*='col-']:last-of-type {
    padding-right: $pad;
  }
}

